import React from 'react'
import { Link } from 'gatsby'
import CardFeature from '../common/CardFeature'

const FeaturedSaleProperties = ({ featuredSale }) => {
  return (
    <div className='properties-area pd-top-92'>
      <div className='container'>
        <div className='section-title'>
          <h2 className='title'>Featured Properties</h2>
          <Link className='btn-view-all' to='/property/for-sale'>
            View All
          </Link>
        </div>
        <div className='row'>
          {featuredSale.nodes.map((item, i) => (
            <div key={i} className='col-lg-3 col-sm-6'>
              <CardFeature {...item} />
              
            </div>
            
          ))}
          
        </div>
        <div className='section-title d-flex justify-content-end'>
          <Link className='btn-view-all' to='/property/for-sale'>
            View More
          </Link>
        </div>
        
      </div>
      
    </div>
  )
}

export default FeaturedSaleProperties
