import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { FaHome } from 'react-icons/fa'

import bgImage from '../../../content/assets/img/bg/2.png'
import icon9 from '../../../content/assets/img/icons/9.png'
import icon10 from '../../../content/assets/img/icons/10.png'



const intro = [
  {
    title: 'House and Land for Sale in Nigeria?',
    url: '/property/for-sale/lagos',
    content:
      'Find property, Houses and Land for sale in Lagos and Abuja.',
    icon: icon9,
    class: 'single-intro-media-active'
  },
  {
    title: 'Invest Abroad, Get Citizenship',
    url: '/property/for-sale/dubai',
    content:
      'Villas, town-houses, apartments for sale in Dubai. Invest Today.',
    icon: icon10
  }
]

const PropertiesByCities = () => {
  
  return (
    <div className='city-intro-area pd-bottom-70' >
      {/* city area start */}
      
      {/* city area end */}
      {/* intro area start */}
      <div className='intro-area pd-top-50'>
        <div className='container'>
          <div className='row'>
            {intro.map((item, i) => (
              <div key={i} className='col-md col-sm-6'>
                <a
                  href={item.url}
                  className={'single-intro-media media d-flex ' + item.class}
                >
                  <div className='media-left'>
                    </div>
                  <div className='media-body'>
                    <h2 className='text-white h4'>{item.title}</h2>
                    <h4 className='h6'>{item.content}</h4>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* intro area start */}
    </div>
  )
}

export default PropertiesByCities
