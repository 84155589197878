import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Nav } from 'react-bootstrap'
import filterData from '../../data/filterData.json'
import { useGlobalContext } from '../../context/globalContext'
import { Typeahead } from 'react-bootstrap-typeahead'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'



import './home.css'

const Banner = () => {
  const { initValue, setInitValue, handleDirect } = useGlobalContext()

  const { contentfulHeroBadge } = useStaticQuery(graphql`
    {
      contentfulHeroBadge(title: { eq: "homepage" }) {
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const image = getImage(contentfulHeroBadge.image)

  const bgImage = convertToBgImage(image)

  const handleChange = e => {
    setInitValue({ ...initValue, [e.target.name]: e.target.value })
  }

  const handleChangeLocation = event => {
    if (!event.length) {
      setInitValue({
        ...initValue,
        singleSelections: event,
        type: ""
      })
    } else {
      setInitValue({
        ...initValue,
        location: event[0].slug,
        singleSelections: event,
        type: ""
      })
    }
  }

  return (
    <BackgroundImage
      Tag='section'
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className='banner-area jarallax position-relative'
    >

<GatsbySeo
title='Land, Houses and Apartments for Sale in Lagos, Abuja Nigeria'
        description='Land, Houses and Apartments for Sale in Lagos, Abuja Nigeria, Verified Listings with genuine titles and Great Return on investment'
        openGraph={{
          title: 'Land, Houses and Apartments for Sale in Lagos, Abuja Nigeria',
          description: 'Land, Houses and Apartments for Sale in Lagos, Abuja Nigeria, Verified Listings with genuine titles and Great Return on investment',
          url: 'https://www.example.com/articles/article-title',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'Og Image Alt Article Title A'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}/>
      <div className='banner-overlay'></div>
      <div className='container'>
        <div className='banner-inner-wrap'>
          <div className='row py-4'>
            <div className='col-lg-10'>
              <div className='banner-inner'>
                
                <h1 className='title text-white'>
                  Houses and Land For Sale in Lagos, Abuja...Dubai Coming Soon
                </h1>
              </div>
            </div>
            <div className='col-12'>
              <div className='banner-search-wrap'>
                <Nav
                  variant='pills'
                  defaultActiveKey={initValue.category}
                  className='rld-banner-tab'
                  value={initValue.category}
                  onSelect={e =>
                    setInitValue(prevState => ({ ...prevState, category: e }))
                  }
                >
                  <Nav.Item>
                    <Nav.Link eventKey='for-sale'>BUY</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='for-rent'>RENT</Nav.Link>
                  </Nav.Item>
                </Nav>

                <div className='tab-content'>
                  <div className='tab-pane fade show active'>
                    <div className='rld-main-search'>
                      <form>
                        <div className='row'>
                          <div className='col-12'>
                            <label
                              htmlFor='location'
                              className='d-inline-block mb-0 pt-2'
                            >
                              Location
                            </label>
                            <Typeahead
                              id='listing'
                              onChange={handleChangeLocation}
                              options={filterData.location}
                              placeholder='Entry Landmark Location'
                              selected={initValue.singleSelections}
                              filterBy={['name']}
                              labelKey={lookuplist => `${lookuplist.name}`}
                            />
                          </div>
                          <div className='rld-single-select col-sm-6 col-xs-6 mb-2 rel'>
                            <div className=''>
                              <label className='lab' htmlFor='type'>Type</label>
                              <select
                                name='type'
                                className=' sel select single-select'
                                value={initValue.type}
                                onChange={handleChange}
                              >
                                <option value=''>All Types</option>
                                {filterData.types.map((type, index) => (
                                  <option key={index} value={type.slug}>
                                    {type.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='col-sm-6 mb-2'>
                            <div className='rld-single-select'>
                              <label className='lab' htmlFor='bedroom'>Bedrooms</label>
                              <select
                                name='bedroom'
                                className='select single-select'
                                value={initValue.bedroom}
                                onChange={handleChange}
                              >
                                <option value=''>Any</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                              </select>
                            </div>
                          </div>
                          <div className='col-sm-6 mb-2'>
                            <div className='rld-single-select'>
                              <label className='lab' htmlFor='minPrice'>Min price</label>
                              <select
                                name='minPrice'
                                className='select single-select'
                                value={initValue.minPrice}
                                onChange={handleChange}
                              >
                                <option value=''>No Min</option>
                                {filterData.priceList.map(item => (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='col-xl-2 col-lg-4 col-md-4'>
                            <div className='rld-single-select'>
                              <label className='lab' htmlFor='maxPrice'>Max price</label>
                              <select
                                name='maxPrice'
                                className='select single-select'
                                value={initValue.maxPrice}
                                onChange={handleChange}
                              >
                                <option value=''>No Max</option>
                                {filterData.priceList.map(item => (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='col-xl-2 col-lg-4 col-md-4 mt-md-4 readeal-top'>
                            <button
                              className='btn btn-yellow'
                              type='button'
                              onClick={handleDirect}
                            >
                              SEARCH NOW
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Banner
