import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/LayoutView'
import Home from '../components/Home'

const HomePage = ({ data }) => {
  return (
    <Layout title='Home'>
      <main>
        <Home data={data} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    heroFeaturedRent: contentfulProperty(
      heroItem: { eq: true }
      propertyCategory: { slug: { eq: "for-rent" } }
    ) {
      ...Property
    }
    featuredRents: allContentfulProperty(
      filter: {
        propertyCategory: { slug: { eq: "for-rent" } }
        featured: { eq: true }
        heroItem: { ne: true }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...Property
      }
    }
    featuredSale: allContentfulProperty(
      filter: {
        propertyCategory: { slug: { eq: "for-sale" } }
        featured: { eq: true }
      }
      sort: { fields: [date], order: DESC }
      limit: 4
    ) {
      nodes {
        ...Property
      }
    }
    fetauredCity: allContentfulProperty {
      group(field: propertyCategoryByCity___slug) {
        totalCount
        cityName: fieldValue
        nodes {
          heroImage {
            gatsbyImageData
          }
        }
      }
    }
    agent: allContentfulPropertyAgent(limit: 6) {
      nodes {
        logo {
          gatsbyImageData(
            layout: CONSTRAINED
            height: 80
            resizingBehavior: THUMB
            quality: 80
          )
        }
        slug
      }
    }
    faq: allContentfulFaq {
      nodes {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    iframe: contentfulIFrame {
      video
      id
    }
  }
`

export default HomePage
