import React from 'react'
import { Link } from 'gatsby'
import { FaWhatsapp } from 'react-icons/fa'

const Ads = () => {

  return (
    <div className='call-to-action-area pd-top-55'>
      <div className='container'>
        <div className='call-to-action'>
          <div className='cta-content'>
            <h3 className='title'>Quickly Find What you Want</h3>
            <Link className='btn btn-white' to='https://wa.me/2348101536132'>
            <FaWhatsapp/>Talk to a Rep
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ads
