import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'

const OurPartner = ({ agent }) => {
  return (
    <div className='client-area pd-top-60 pd-bottom-40'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2 className='title'>Our Partners</h2>
        </div>
        <div className='client-slider row'>
          {agent.nodes.map((item, i) => (
            
            <h2
              key={i}
              className='item col-sm-12 col-md-3 col-lg-2 p-1 mx-4 text-center  '
              style={{ width: '200px', height: '100px' }}
            >
              <Link to={`/real-estate-companies/${item.slug}`}>
                <Img
                  image={getImage(item.logo)}
                  alt='client'
                  className='mx-auto'
                />
              </Link>
            </h2>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurPartner
