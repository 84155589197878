import React from 'react'
import Iframe from './iFrame'
import Banner from './banner'
import Explore from './explore'
import FeaturedRentProperties from './featured-rent-properties'
import Ads from './ads'
import PropertiesByCities from './properties-by-cities'
import FeaturedSaleProperties from './featured-sale-properties'
import WhyChooseUs from './why-choose-us'
import OurPartner from './our-partner'
import Faq from './faq'
import { Hero } from '../Adsense/hero'

const Home = ({ data}) => {
  return (
    <React.Fragment>
      

      <Banner />
     
      
      <FeaturedSaleProperties
        featuredSale={data.featuredSale}
        heroItem={data.heroFeaturedRent}
      />
      <Ads />
      
      {/*<FeaturedSaleProperties featuredSale={data.featuredSale} />*/}
      {/*<WhyChooseUs />*/}
      
      <OurPartner agent={data.agent} />
      <PropertiesByCities fetauredCity={data.fetauredCity} />
      <Iframe video={data.iframe.video} />
      
      {/* <Faq faq={data.faq}/>*/}
    </React.Fragment>
  )
}

export default Home
