import React from 'react'

const Iframe = ({ video }) => {
  return (
    <div className='container margin-auto' style={{ height: '80vh' }}>
     <a className='btn btn-lg btn-block btn-outline-success btn-block d-flex justify-content-center' href='https://www.youtube.com/channel/UCmG9s_05obMChmd0DZwUZrA' target="_blank">Subscribe on Youtube</a>
      <iframe
        width='100%'
        height='100%'
        src="https://www.youtube.com/embed/QgPKHKmZZ-Q"
        frameBorder='0'
        className='responsive-iframe relative w-100 h-100'
        allow='autoplay;encrypted-media'
        allowFullScreen
        
      ></iframe>
    <div></div>
    
    </div>
    
  )
}

export default Iframe
